import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    audioLoading: false,
    userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
  },
  getters: {
  },
  mutations: {
    changeState(state, payload) {
      state[payload.key] = payload.state
    },

    setUserInfo(state, payload) {
      state.userInfo = payload
      sessionStorage.setItem("userInfo", JSON.stringify(payload));

    }
  },
  actions: {
  },
  modules: {
  }
})
