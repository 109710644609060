import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',

    component: () => import('@/views/Home'),
    children: [{
      path: 'infoManage',
      name: 'InfoManage',
      meta: {
        key: 'info',
      },
      component: () => import('@/views/InfoManage')
    }, {
      path: 'start',
      name: 'Start',
      meta: {
        key: 'start',
      },
      component: () => import('@/views/Start')
    }, {
      path: 'pay',
      name: 'Pay',
      meta: {
        key: 'pay',
      },
      component: () => import('@/views/Pay')
    },
    {
      path: 'resumeSuccess',
      name: 'ResumeSuccess',
      meta: {
        key: 'resumeSuccess',
      },
      component: () => import('@/views/ResumeSuccess')
    },
    {
      path: '',
      redirect: '/home/infoManage'
    }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  }, {
    path: '/editPwd',
    name: 'EditPwd',
    component: () => import('@/views/EditPwd')
  }, {
    path: '',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    const tag_id = JSON.parse(sessionStorage.getItem('userInfo'))?.tag_id || ''
    if (tag_id) {
      next()
    } else {
      next('/login')
    }
  }
})
export default router
