import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'highlight.js/styles/github.css'; // 你也可以选择其他主题


import './style/global.scss';
import './style/element-variables.scss';

Vue.config.productionTip = false
Vue.use(ElementUI);
import hljs from 'highlight.js';

// 封装成一个指令
Vue.directive('highlight', (el) => {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
