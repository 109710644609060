<template>
  <div id="app" v-loading.fullscreen.lock="loading">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    handleGo(path) {
      this.$router.push(path);
    },
  },
  watch: {
    $route(newValue) {
      this.navKey = newValue.meta.key;
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  background: #f7f8fc;
  .main {
    width: 100%;
    height: 100%;
  }
  /* 全局滚动条样式 */
  ::-webkit-scrollbar {
    width: 12px;
    /* 滚动条宽度 */
    height: 12px;
    /* 滚动条高度 */
    display: none;
  }
}
</style>
